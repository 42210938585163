import $ from "jquery";
import * as bootstrap from "bootstrap";
import "@selectize/selectize/dist/js/standalone/selectize";

var tempusDominus = require('../../node_modules/@eonasdan/tempus-dominus/dist/js/tempus-dominus.js');

export function initTooltips() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
}

export function initScrollspy() {
    const scrollSpy = new bootstrap.ScrollSpy(document.body, {
        smoothScroll: true,
        target: '#jump-links'
    });
}

export function initSelectize() {
    $(".form-select.search-select").selectize({
        closeAfterSelect: true
    });
    $(".form-select.search-select").removeClass("form-select");
}

export function initPickers() {
    // Time fields
    // const timeTriggerList = document.querySelectorAll('[data-lo-picker="time"]');
    // const timeList = [...timeTriggerList].map(timeTriggerEl => new tempusDominus.TempusDominus(timeTriggerEl, {
    //     localization: {
    //         locale: "en-AU",
    //         format: "hh:mm T"
    //     },
    //     display: {
    //         components: {
    //             calendar: false,
    //             date: false,
    //             month: false,
    //             year: false,
    //             decades: false,
    //             clock: true,
    //             hours: true,
    //             minutes: true,
    //             seconds: false,
    //         },
    //         viewMode: "clock",
    //         buttons: {
    //             clear: true,
    //         },
    //     },
    //     stepping: 5,
    // }));

    // Past date fields
    const tonight = new Date();
    tonight.setHours(23,59,59,59);
    const pastDateTriggerList = document.querySelectorAll('[data-lo-picker="pastdate"]');
    const pastDateList = [...pastDateTriggerList].map(pastDateTriggerEl => new tempusDominus.TempusDominus(pastDateTriggerEl, {
        localization: {
            locale: "en-AU",
            format: "dd/MM/yyyy"
        },
        display: {
            components: {
                hours: false,
                minutes: false,
                seconds: false
            },
            buttons: {
                clear: true,
            },
        },
        restrictions: {
            maxDate: tonight
        },
        useCurrent: false
    }));

    // Future date fields
    const today = new Date();
    today.setHours(0,0,0,0);
    const futureDateTriggerList = document.querySelectorAll('[data-lo-picker="futuredate"]');
    const futureDateList = [...futureDateTriggerList].map(futureDateTriggerEl => new tempusDominus.TempusDominus(futureDateTriggerEl, {
        localization: {
            locale: "en-AU",
            format: "dd/MM/yyyy"
        },
        display: {
            components: {
                hours: false,
                minutes: false,
                seconds: false
            },
            buttons: {
                clear: true,
            },
        },
        restrictions: {
            minDate: today
        },
        useCurrent: false
    }));
}
