// Load Styles
import '../scss/app.scss';

import $ from "jquery";
import * as bootstrap from "bootstrap";
import _ from 'lodash'
import "simplebar";
import Chart from 'chart.js/auto';
import Dropzone from "dropzone";
import Sortable from 'sortablejs';
import InfiniteAjaxScroll from "@webcreate/infinite-ajax-scroll"
import "./confirm.js";
import {
    initTooltips,
    initScrollspy,
    initSelectize,
    initPickers,
} from "./helpers";

import Sticky from "sticky-js/dist/sticky.compile";

const sticky = new Sticky('.sticky');

import "@melloware/coloris/dist/coloris.css";
import { coloris, init } from "@melloware/coloris";
init();

const body_el = document.querySelector("body");

// Bootstrap Toast
const toastElList = document.querySelectorAll('.toast')
const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl))

// Add Bootstrap class to input type radio
$('[type="radio"]').addClass("form-check-input");
$('[type="checkbox"]').addClass("form-check-input");

initSelectize();
initPickers();
initTooltips();
initScrollspy();

// Check if there are messages to display
if ($(".messages li").length) {
    $("body").addClass("has-messages");
}

/*
 * Activity Stream
 */
let stream_endpoint = document.querySelector('[data-stream-endpoint');
if (stream_endpoint) {
    stream_endpoint = stream_endpoint.dataset.streamEndpoint;

    function auto_refresh_stream() {
        $("#stream-container").load(stream_endpoint, function () {
            let allCheckboxes = document.querySelectorAll('.timeline-filters input[type=checkbox]');
            let allTimeline = Array.from(document.querySelectorAll('.timeline-container'));
            let checked = {};

            getChecked('action');
            getChecked('source');
            getChecked('status');

            Array.prototype.forEach.call(allCheckboxes, function (el) {
            el.addEventListener('change', toggleCheckbox);
            });

            function toggleCheckbox(e) {
            getChecked(e.target.name);
            setVisibility();
            }

            function getChecked(name) {
            checked[name] = Array.from(document.querySelectorAll('input[name=' + name + ']:checked')).map(function (el) {
                return el.value;
            });
            }

            function setVisibility() {
            allTimeline.map(function (el) {
                let actions = checked.action.length ? _.intersection(Array.from(el.classList), checked.action).length : true;
                let sources = checked.source.length ? _.intersection(Array.from(el.classList), checked.source).length : true;
                let status = checked.status.length ? _.intersection(Array.from(el.classList), checked.status).length : true;
                if (actions && sources && status) {
                el.style.display = 'table';
                } else {
                el.style.display = 'none';
                }
            });
            }
            //setTimeout(auto_refresh_stream, 30000);
        });
    }
    auto_refresh_stream();
}



/*
 * Auto grow textareas
 */
function initAutoGrowTextareas() {
    const growers = document.querySelectorAll(".grow-wrap");

    growers.forEach((grower) => {
        const textarea = grower.querySelector("textarea");
        if (textarea) {
            grower.dataset.replicatedValue = textarea.value;
            textarea.addEventListener("input", () => {
                grower.dataset.replicatedValue = textarea.value;
            });
        }
    });
}

initAutoGrowTextareas();

/*
 * Left Menu
 */
function App() {
    this.body = $("body"),
        this.window = $(window),
        this.menuContainer = $("#leftside-menu-container")
}

App.prototype.activateCondensedSidebar = function () {
        this.body.attr("data-leftbar-compact-mode", "condensed");
        window.localStorage.setItem("lo-dashboard-menu", "condensed");
    },
    App.prototype.deactivateCondensedSidebar = function () {
        this.body.removeAttr("data-leftbar-compact-mode");
        window.localStorage.removeItem("lo-dashboard-menu");
    }

App.prototype.initMenu = function () {
        var t, e = this;
        // Remember user preference
        if (window.localStorage.getItem("lo-dashboard-menu")) {
            e.body.addClass("sidebar-enable");
            e.activateCondensedSidebar();
        } else {
            if (e.window.width() > 1024) {
                e.deactivateCondensedSidebar();
            }
        }
        $(document).on("click", ".button-menu-mobile", function (t) {
            t.preventDefault(),
                e.body.toggleClass("sidebar-enable"),
                "full" === e.body.attr("data-layout") || e.window.width() < 768 ? e.body.toggleClass("hide-menu") : "condensed" === e.body.attr("data-leftbar-compact-mode") ? e.deactivateCondensedSidebar() : e.activateCondensedSidebar()
        });
        $(".side-nav").length && (t = $(".side-nav li .collapse"),
            $(".side-nav li [data-bs-toggle='collapse']").on("click", function (t) {
                return !1
            }),
            t.on({
                "show.bs.collapse": function (t) {
                    var e = $(t.target).parents(".collapse.show");
                    $(".side-nav .collapse.show").not(t.target).not(e).collapse("hide")
                }
            }),
            $(".side-nav a").each(function () {
                var t, e, a, o = window.location.href.split(/[?#]/)[0];
                // Remove to prevent partial matches being markeed as active
                if (o.includes(this.href)) {
                    $(".side-nav .active").removeClass("active");
                    $(".side-nav .menuitem-active").removeClass("menuitem-active");
                }
                o.includes(this.href) && ($(this).addClass("active"),
                    $(this).parent().addClass("menuitem-active"),
                    $(this).parent().parent().parent().addClass("show"),
                    $(this).parent().parent().parent().parent().addClass("menuitem-active"),
                    "sidebar-menu" !== (t = $(this).parent().parent().parent().parent().parent().parent()).attr("id") && t.addClass("show"),
                    $(this).parent().parent().parent().parent().parent().parent().parent().addClass("menuitem-active"),
                    "wrapper" !== (e = $(this).parent().parent().parent().parent().parent().parent().parent().parent().parent()).attr("id") && e.addClass("show"),
                    (a = $(this).parent().parent().parent().parent().parent().parent().parent().parent().parent().parent()).is("body") || a.addClass("menuitem-active"))
            }));
        var a = document.querySelectorAll("ul.navbar-nav .dropdown .dropdown-toggle"),
            o = !1;
        a.forEach(function (a) {
            a.addEventListener("click", function (t) {
                    var e;
                    a.parentElement.classList.contains("nav-item") || (o = !0,
                        e = a.parentElement.parentElement.parentElement.querySelector(".nav-link"),
                        bootstrap.Dropdown.getInstance(e).show(),
                        a.ariaExpanded ? bootstrap.Dropdown.getInstance(a).hide() : bootstrap.Dropdown.getInstance(e).show(),
                        o = !0)
                }),
                a.addEventListener("hide.bs.dropdown", function (t) {
                    o && (t.preventDefault(),
                        t.stopPropagation(),
                        o = !1)
                }),
                a.addEventListener("show.bs.dropdown", function (t) {
                    o || a.parentElement.classList.contains("nav-item") || (t.preventDefault(),
                        t.stopPropagation(),
                        o = !0)
                })
        })
    },
    App.prototype.init = function () {
        this.initMenu();
    }
$.LeftSidebar = new App;
$.LeftSidebar.Constructor = App;
$.LeftSidebar.init();

/*
 * Clickable table rows
 */
let isCommandPressed = false;

window.addEventListener("keydown", (event) => {
    if (event.which === 91) {
        isCommandPressed = true;
        console.log(isCommandPressed);
    }
});

window.addEventListener("keyup", (event) => {
    if (event.which === 91) {
        isCommandPressed = false;
        console.log(isCommandPressed);
    }
});

const rows = document.querySelectorAll("[data-row-url]");

if (rows) {
    rows.forEach((row) => {
        let rowLink = row.dataset.rowUrl;
        const cells = row.querySelectorAll("td:not(.no-row-click)");
        cells.forEach((cell) => {
            cell.addEventListener("click", () => {
                if (isCommandPressed) {
                    window.open(rowLink, "_blank");
                } else {
                    window.location.href = rowLink;
                }
            });
        });
    });
}

/*
 * Dependent Selects
 * Usage: Business Categories/Sub Categories
 */
$.fn.dependent = function (opts) {
    opts.data = {};
    this.each(function () {
        $.each(opts.chain, function (index, selectID) {
            var ident = selectID.substr(1);
            var selectHTML = $(selectID).html();
            opts.data[ident] = {};
            $('<select>' + selectHTML + '</select>').find('optgroup').each(function () {
                var optGroup = $('<div>').append($(this).eq(0).clone()).html();
                var group = $(this).attr('label');
                opts.data[ident][group] = optGroup;
            });
            if (opts.chain[index + 1]) {
                $(opts.chain[index]).bind('change', {
                    'nextID': opts.chain[index + 1]
                }, binding);
            }
        });
        $(this).bind('change', {
            'nextID': opts.chain[0]
        }, binding);
        $(this).trigger('change');

        function binding(event) {
            var next = event.data.nextID.substr(1);
            $('#' + next).html(opts.data[next][$(this).val()]);
            $('#' + next).trigger('change');
        }
    });
}

/*
 * Debounce
 */
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};



/*
 * Check password strength
 * Likely to be removed as part of SSO
 */

function checkStrength(password) {

    //initial strength
    var strength = 0

    //if the password length is less than 6, return message.
    if (password.length < 6) {
        $('#password-strength').removeClass()
        $('#password-strength').addClass('short')
        $('#password-update').attr('disabled', 'disabled');
        return 'Too short'
    }

    //length is ok, lets continue.

    //if length is 8 characters or more, increase strength value
    if (password.length > 7) strength += 1

    //if password contains both lower and uppercase characters, increase strength value
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) strength += 1

    //if it has numbers and characters, increase strength value
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) strength += 1

    //if it has one special character, increase strength value
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) strength += 1

    //if it has two special characters, increase strength value
    if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,",%,&,@,#,$,^,*,?,_,~])/)) strength += 1

    //now we have calculated strength value, we can return messages

    //if value is less than 2
    if (strength < 2) {
        $('#password-strength').removeClass()
        $('#password-strength').addClass('weak')
        $('#password-update').attr('disabled', 'disabled');
        return 'Weak'
    } else if (strength == 2) {
        $('#password-strength').removeClass()
        $('#password-strength').addClass('good')
        $('#password-update').removeAttr("disabled", "disabled");
        return 'Good'
    } else {
        $('#password-strength').removeClass()
        $('#password-strength').addClass('strong')
        $('#password-update').removeAttr("disabled", "disabled");
        return 'Strong'
    }
}

/*
 * Load top bar so data is available on all pages
 */
function populateUserDetails() {
    $.ajax({
        url: "/dashboard/get-user-details/",
        success: function (data) {
            $("#ajax-login-wrapper").html(data);

            // Switch User Modal
            const searchInput = document.getElementById('searchInput');
            const resultContainer = document.getElementById('resultContainer');
            let filteredResults = searchResults
            let activeIndex = 0;
            
            // Function to perform search
            function performSearch(query) {
                // Clear previous results
                resultContainer.innerHTML = '';

                // Filter search results
                filteredResults = searchResults.filter(result =>
                result.title.toLowerCase().includes(query.toLowerCase())
                );

                // Display search results
                filteredResults.forEach((result, index) => {
                const resultItem = document.createElement('div');
                resultItem.classList.add('result-item');
                resultItem.innerHTML = `<h4>${result.title}</h4>`;
                resultItem.addEventListener('click', () => {
                    navigateToResult(result.url);
                });
                resultItem.addEventListener('mouseenter', () => {
                    setActiveItem(index);
                });
                resultContainer.appendChild(resultItem);
                });
                setActiveItem(0);
            }

            // Function to set active item
            function setActiveItem(index) {
                const resultItems = document.querySelectorAll('.result-item');
                resultItems.forEach(item => item.classList.remove('active'));
                if (index >= 0 && index < resultItems.length) {
                resultItems[index].classList.add('active');
                resultItems[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
                activeIndex = index;
            }

            // Function to navigate to result URL
            function navigateToResult(url) {
                if (url) {
                window.location.href = url;
                }
            }

            // Event listener for input change
            searchInput.addEventListener('input', () => {
                const query = searchInput.value.trim();
                performSearch(query);
            });

            // Event listener for arrow key navigation
            searchInput.addEventListener('keydown', (e) => {
                if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    if (activeIndex < filteredResults.length - 1) {
                        setActiveItem(activeIndex + 1);
                    }
                } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    if (activeIndex > 0) {
                        setActiveItem(activeIndex - 1);
                    }
                } else if (e.key === 'Enter') {
                    e.preventDefault();
                    const url = filteredResults[activeIndex].url;
                    navigateToResult(url);
                }
            });

            const myModal = document.getElementById('user-switcher-modal')

            myModal.addEventListener('shown.bs.modal', () => {
                searchInput.focus();
            });

            performSearch('');

            // Support filtering user list
            $("#user-filter").on("keyup", function () {
                var filter = $(this).val().toUpperCase();
                $("#user-dropdown .dropdown-item").each(function () {
                    var txtValue = $(this).text();
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        this.style.display = "";
                    } else {
                        this.style.display = "none";
                    }
                });
            });

            const switchUsers = document.getElementById('switch-users');
            if (switchUsers) {
                switchUsers.addEventListener('shown.bs.dropdown', event => {
                    $('#user-filter').trigger('focus');
                });
            }

            // Dashboard Search
            $('.dashboard-search').on('keyup', debounce(function () {
                var $this = $(this);
                var searchString = $this.val();
                var url = "/dashboard/search/";
                var $results = $('.dashboard-search-results');
                var targetOffset = $(".search-form-wrapper").offset();
                if (searchString.length > 2) {
                    $.ajax({
                        type: "GET",
                        url: url,
                        data: {
                            q: searchString
                        },
                        success: function (data) {
                            $results.empty().prepend(data).css({
                                "left": targetOffset.left - 16,
                                "top": 49
                            }).width($(".search-form-wrapper").width()).removeClass("d-none");
                            $(document).on('click.search.cancel', (e2) => {
                                const relTarg = e2.relatedTarget || e2.toElement;

                                if (!$(relTarg).parents().is($results)) {
                                    $results.empty().addClass("d-none");
                                    $(document).off('click.search.cancel');
                                }
                            });
                        }
                    });
                } else {
                    $results.empty().addClass("d-none");
                    $(document).off('click.search.cancel');
                }
            }, 500));
        }
    });
}

/*
 * Sticky Table Header Position
 */
if ($(".sticky-table-header").length) {
    $(".sticky-table-header").css("top", $(".messages").height() + $(".header-wrapper").height());
}

// Show hidden sections if data has been entered
$('.listing-content input:text, .listing-content textarea').each(function () {
    if ($(this).val() != '') {
        $(this).closest('.listing-content').show();
        var symbol = '&minus;';
        $(this).closest('.listing-content').prev('.listing-heading').children().html(symbol);
    }
});
$(".listing-content input:checkbox:checked").each(function () {
    $(this).closest('.listing-content').show();
    var symbol = '&minus;';
    $(this).closest('.listing-content').prev('.listing-heading').children().html(symbol);
});
$(".listing-content input:radio:checked").each(function () {
    $(this).closest('.listing-content').show();
    var symbol = '&minus;';
    $(this).closest('.listing-content').prev('.listing-heading').children().html(symbol);
});
$(".listing-content select option:selected").each(function () {
    if ($(this).val() != '') {
        $(this).closest('.listing-content').show();
        var symbol = '&minus;';
        $(this).closest('.listing-content').prev('.listing-heading').children().html(symbol);
    }
});


$(document).ready(function () {

    $("#system-messages").delay(5000).slideUp();
    //Load user details & drop down
    populateUserDetails();

    function activateTab($tab) {
        var $activeTab = $tab.closest('dl').find('a.active'),
            contentLocation = $tab.attr("href") + 'Tab';

        // Strip off the current url that IE adds
        contentLocation = contentLocation.replace(/^.+#/, '#');

        //Make Tab Active
        $activeTab.removeClass('active');
        $tab.addClass('active');

        //Show Tab Content
        $(contentLocation).closest('.tabs-content').children('li').hide();
        $(contentLocation).css('display', 'block');

        //Trigger events
        $($activeTab.attr('href') + 'Tab').trigger('tab:deactivated');
        $(contentLocation).trigger('tab:activated');
    }

    $('dl.tabs').each(function () {
        //Get all tabs
        var tabs = $(this).children('dd').children('a');
        tabs.click(function (e) {
            activateTab($(this));
        });
    });

    if (window.location.hash) {
        activateTab($('a[href="' + window.location.hash + '"]'));
    } else {
        // activate first tab
        activateTab($("dl.tabs").first().find('dd a[href]').first());
    }
    $('#id_new_password1,#id_password1').keyup(function () {
        $('#password-strength').html(checkStrength($(this).val()));
    })


    $('#back-button').click(function () {
        window.history.back();
        return false;
    });

    $('#stocklist-print-button').click(function () {
        window.print();
        return false;
    });

    // Enable/disable portal tabs
    $('a.switch').click(function () {
        var currentText = $(this).text();
        $.ajax({
            url: $(this).attr('href'),
            cache: false,
            success: function () {
                $('#save-as-draft').click();
            }
        });
        return false;
    });
    // AJAX links
    $('a.ajax').click(function () {
        $.ajax({
            url: $(this).attr('href'),
            cache: false,
            success: function () {
                location.reload(true);
            }
        });
        return false;
    });






    // Show/hide form sections
    $(".show-advanced").click(function (event) {
        $('.advanced').show().effect("highlight", {}, 1500);
        $(".show-advanced").hide();
    });

    // Business Category/Subcategory	
    $('#id_listing-business_category_1').dependent({
        chain: ['#id_listing-business_sub_category_1']
    });
    $('#id_listing-business_category_2').dependent({
        chain: ['#id_listing-business_sub_category_2']
    });
    $('#id_listing-business_category_3').dependent({
        chain: ['#id_listing-business_sub_category_3']
    });
    $('#id_business_category_1').dependent({
        chain: ['#id_business_sub_category_1']
    });
    $('#id_business_category_2').dependent({
        chain: ['#id_business_sub_category_2']
    });
    $('#id_business_category_3').dependent({
        chain: ['#id_business_sub_category_3']
    });

    $('#id_category').dependent({
        chain: ['#id_sub_category']
    });
});

function initMultiloadSwitch() {
    $('[data-multiload-switch]').on("click", function () {
        var url = $(this).data("multiloadSwitch");
        var option = 'disable';
        if ($(this).is(':checked')) {
            option = 'enable';
        }
        $.ajax({
            url: '/' + option + '-multiload' + url + '/',
            cache: false,
            success: function () {
                //$('#save-as-draft').click();
            }
        });
    });
}
initMultiloadSwitch();

$('#add-another-link').on("click", function (event) {
    var numRows = parseInt($('#id_externallink_set-TOTAL_FORMS').val()) + 1;
    $("#id_externallink_set-TOTAL_FORMS").val(numRows);
    var newFormId = numRows - 1;
    $('#link_formset').append('<div class="row" style="padding-bottom: 5px;"><div class="five columns"><div class="field-element" style="margin-bottom:5px;"><div class="border"><input name="externallink_set-' + newFormId + '-url" id="id_externallink_set-' + newFormId + '-url"></input></div></div></div><div class="five columns"><div class="field-element" style="margin-bottom:5px;"><div class="border"><input name="externallink_set-' + newFormId + '-title" id="id_externallink_set-' + newFormId + '-title"></input></div></div></div><div class="one columns"><div class="field-element checkbox"><div class="border"><input type="checkbox" style="display:none;" name="form-' + newFormId + '-DELETE" id="id_form-' + newFormId + '-DELETE" /><span class="custom checkbox" onclick="$(this).parents(\'.row\').prev(\'hr\').remove();$(this).parents(\'.row\').remove();"></span><input type="hidden" name="id_externallink_set-' + newFormId + '-id" id="id_externallink_set-' + newFormId + '-id" /></div></div></div></div>');
    // Input field focus additional styles
    $("input,textarea,select").on("focus", function () {
        $(this).closest("div.border").addClass('active').closest("div.field-element").addClass('active');
    });
    $("input,textarea,select").on("blur", function () {
        $(this).closest("div.border").removeClass('active').closest("div.field-element").removeClass('active');
    });
    $("select").chosen();
    return false;
});




$(".form-dropdown button").on("click", function () {
    $(".form-button-processing").removeClass("d-none");
    $(".form-button-ready").addClass("d-none");
});


$(".is-invalid").each(function () {
    $(this).closest(".form-section").addClass("invalid");
});



function createElements(tagName, contentString) {
    var elem = document.createElement(tagName);
    elem.innerHTML = contentString;
    return elem.childNodes;
}

function initFormset(formsetPrefix, formAddedCallback) {
    var emptyForm = document.getElementById(formsetPrefix + '-empty-form');
    var template = emptyForm.text;
    var templateTagName = emptyForm.dataset.element || 'div';
    var totalField = document.getElementById('id_' + formsetPrefix + '-TOTAL_FORMS');
    var formset = document.getElementById(formsetPrefix + '-formset');

    document.getElementById('add-' + formsetPrefix).addEventListener('click', function (event) {
        event.preventDefault();
        var total = parseInt(totalField.value);
        var childNodes = createElements(templateTagName, template.replace(/__prefix__/g, total));
        while (childNodes.length) {
            formset.appendChild(childNodes[0]);
        }
        totalField.value = total + 1;
        if (formAddedCallback) {
            formAddedCallback();
        }
        return false;
    });
}

function listingExtraFieldNameAutocomplete(jqueryElement) {
    jqueryElement.autocomplete({
        source: "/autocomplete-listing-extra-field-name",
        focus: function () {
            // prevent value being inserted on focus
            return false;
        },
    });
}

/* 
 * Autocompletes
 */

function initializeAutocomplete(id) {
    const element = document.getElementById(id);
    if (element) {
        const options = {
            componentRestrictions: { country: "au" },
            fields: ["address_components"],
        };
        let autocomplete = new google.maps.places.Autocomplete(element, options);
        google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChanged);
    }
}
  
function onPlaceChanged() {
    var place = this.getPlace();
    const component_map = {
        "subpremise": "id_listing-address_sub_number",
        "street_number": "id_listing-address_street_number",
        "route": "id_listing-address_street_name",
        "locality": "id_listing-address_suburb",
        "postal_code": "id_listing-address_postcode",
        "administrative_area_level_1": "id_listing-address_state",
        "administrative_area_level_2": "id_listing-address_municipality"
    }

    // Clear fields as some are not supported or not always present
    document.getElementById('id_listing-address_lot_number').value = '';
    document.getElementById('id_listing-address_sub_number').value = '';
    document.getElementById('id_listing-address_street_number').value = '';
    document.getElementById('id_listing-address_street_name').value = '';
    // Places does not return a street type field
    $('#id_listing-address_street_type')[0].selectize.clear();

  
    for (var i in place.address_components) {
        var component = place.address_components[i];
        for (var j in component.types) {  // Some types are ["country", "political"]
            var type_element = document.getElementById(component_map[component.types[j]]);
            let component_val = component.long_name;
            if (component.types[j] == 'subpremise' && component.short_name.toLowerCase().includes('lot')) {
                type_element = document.getElementById('id_listing-address_lot_number');
                component_val = component_val.toLowerCase().replace('lot','').trim();
            }
            if (component.types[j] == 'administrative_area_level_1') {
                component_val = component.short_name;
            }
            if (type_element) {
                type_element.value = component_val;
            }
        }
    }
}

const locationSearch = document.getElementById('id_search_location');
if(locationSearch) {
    google.maps.event.addDomListener(window, 'load', function() {
        initializeAutocomplete('id_search_location');
    });
}
/*
 * Form Side Close
 */
const initSidePanel = () => {
    const setPanel = (panelName) => {
        const sidePanelWrapper = document.querySelector('[data-form-side]');
        const body = document.querySelector('body');
        // Open / close side panel

        if (panelName === '') {
            sidePanelWrapper.classList.remove('form-side--open');
            sidePanelWrapper.removeAttribute('aria-labelledby');
        } else {
            sidePanelWrapper.classList.add('form-side--open');
            sidePanelWrapper.setAttribute(
                'aria-labelledby',
                `side-panel-${panelName}-title`,
            );
        }

        document.querySelectorAll('[data-side-panel]').forEach((panel) => {
            if (panel.dataset.sidePanel === panelName) {
                if (panel.hidden) {
                    panel.hidden = false;
                    //panel.dispatchEvent(new CustomEvent('show'));
                    body.classList.add('side-panel-open');
                }
            } else if (!panel.hidden) {
                panel.hidden = true;
                //panel.dispatchEvent(new CustomEvent('hide'));
                body.classList.remove('side-panel-open');
            }
        });

        // Update aria-expanded attribute on the panel toggles
        document.querySelectorAll('[data-side-panel-toggle]').forEach((toggle) => {
            toggle.setAttribute(
                'aria-expanded',
                toggle.dataset.sidePanelToggle === panelName ? 'true' : 'false',
            );
        });
    };

    const togglePanel = (panelName) => {
        const isAlreadyOpen = !document
            .querySelector(`[data-side-panel="${panelName}"]`)
            .hasAttribute('hidden');

        if (isAlreadyOpen) {
            // Close the sidebar
            setPanel('');
        } else {
            // Open the sidebar / navigate to the panel
            setPanel(panelName);
        }
    };

    document.querySelectorAll('[data-side-panel-toggle]').forEach((toggle) => {
        toggle.addEventListener('click', () => {
            togglePanel(toggle.dataset.sidePanelToggle);
        });
    });

    const closeButton = document.querySelector('[data-form-side-close-button]');
    if (closeButton instanceof HTMLButtonElement) {
        closeButton.addEventListener('click', () => {
            setPanel('');
        });
    }
};

initSidePanel();

$('.form-dropdown').each(function () {
    const $dropdown = $(this);
    $('.form-dropdown-toggle', $dropdown).on('click', (e) => {
        e.stopPropagation();
        $dropdown.toggleClass('open');

        if ($dropdown.hasClass('open')) {
            // If a dropdown is opened, add an event listener for document clicks to close it
            $(document).on('click.dropdown.cancel', (e2) => {
                const relTarg = e2.relatedTarget || e2.toElement;

                // Only close dropdown if the click target wasn't a child of this dropdown
                if (!$(relTarg).parents().is($dropdown)) {
                    $dropdown.removeClass('open');
                    $(document).off('click.dropdown.cancel');
                }
            });
        } else {
            $(document).off('click.dropdown.cancel');
        }
    });
});

let updateFooterTextTimeout = -1;
window.updateFooterSaveWarning = (formDirty) => {
    const warningContainer = $('[data-unsaved-warning]');
    if (formDirty) {
        warningContainer.removeClass('d-none');
    } else {
        warningContainer.addClass('d-none');
    }
    clearTimeout(updateFooterTextTimeout);
};

/*
 * Enables a "dirty form check", prompting the user if they are navigating away
 * from a page with unsaved changes, as well as optionally controlling other
 * behaviour via a callback
 *
 * It takes the following parameters:
 *
 *  - formSelector - A CSS selector to select the form to apply this check to.
 *
 *  - options - An object for passing in options. Possible options are:
 *  - confirmationMessage - The message to display in the prompt.
 *  - alwaysDirty - When set to true the form will always be considered dirty,
 *    prompting the user even when nothing has been changed.
 *  - callback - A function to be run when the dirty status of the form
 *    system (if using) changes, taking formDirty as argument
 */

window.enableDirtyFormCheck = (formSelector, options) => {
    const $form = $(formSelector);
    const confirmationMessage = options.confirmationMessage || ' ';
    const alwaysDirty = options.alwaysDirty || false;
    const callback = options.callback || null;
    let initialData = null;
    let formSubmitted = false;

    const updateCallback = (formDirty) => {
        if (callback) {
            callback(formDirty);
        }
    };

    $form.on('submit', () => {
        formSubmitted = true;
    });

    let isDirty = alwaysDirty;

    updateCallback(isDirty);

    let updateIsDirtyTimeout = -1;

    const isFormDirty = () => {
        if (alwaysDirty) {
            return true;
        } else if (!initialData) {
            return false;
        }

        if ($form.serialize() !== initialData) {
            return true;
        }

        return false;
    };

    const updateIsDirty = () => {
        const previousIsDirty = isDirty;
        isDirty = isFormDirty();
        if (previousIsDirty !== isDirty) {
            updateCallback(isDirty);
        }
    };

    if (!alwaysDirty) {
        setTimeout(() => {
            initialData = $form.serialize();

            const updateDirtyCheck = () => {
                clearTimeout(updateIsDirtyTimeout);
                updateIsDirtyTimeout = setTimeout(updateIsDirty, isDirty ? 3000 : 300);
            };

            $form.on('change keyup', updateDirtyCheck).trigger('change');

        }, 1000 * 10);
    }

    window.addEventListener('beforeunload', (event) => {
        clearTimeout(updateIsDirtyTimeout);
        updateIsDirty();
        const displayConfirmation = !formSubmitted && isDirty;

        if (displayConfirmation) {
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
    });
};

/*
 * Offices
 */
if(body_el.classList.contains("client-form")) {
    coloris({alpha: false, theme: "polaroid", el: "#id_primary_colour"});
    coloris({alpha: false, theme: "polaroid", el: "#id_secondary_colour"});
    coloris({alpha: false, theme: "polaroid", el: "#id_third_colour"});

    $('#alternatePostal').on("change", function () {
        if ($(this).prop('checked')) {
            $('#postal-details').removeClass("d-none");
        } else {
            $('#id_postal_address').val('');
            $('#id_postal_suburb').val('');
            $('#id_postal_postcode').val('');
            $('#id_postal_state').find('option:first-child').prop('selected', true).val('');
            $('#postal-details').addClass("d-none");
        };
    });

    $('#leaseContact').on("change", function () {
        if ($(this).prop('checked')) {
            $('#lease-details-section').removeClass("d-none");
        } else {
            $('#lease-details-section').find('input').val('');
            $('#lease-details-section').addClass("d-none");
        }
    });
}

/*
 * Agents
 */
if(body_el.classList.contains("agent-form")) {
    // Videos
    bind_add_button($('#add-video').on('click', bind_add_button('video')));
    Sortable.create(document.getElementById("videos"), {
        onEnd: function() {
            $(".video-can-order-form").each(function(i){
                $(this).find('input[id$=ORDER]').val(i+1);
            });
        }
    });
    // Q&A
    bind_add_button($('#add-qanda').on('click', bind_add_button('qanda')));
    Sortable.create(document.getElementById("qandas"), {
        onEnd: function() {
            $(".qanda-can-order-form").each(function(i){
                $(this).find('input[id$=ORDER]').val(i+1);
            });
        }
    });
    // Skills
    bind_add_button($('#add-skillorexpertise').on('click', bind_add_button('skillorexpertise')));
    Sortable.create(document.getElementById("skillorexpertises"), {
        onEnd: function() {
            $(".skillorexpertise-can-order-form").each(function(i){
                $(this).find('input[id$=ORDER]').val(i+1);
            });
        }
    });
    // Team Member
    bind_add_button($('#add-teammember').on('click', bind_add_button('teammember')));
    Sortable.create(document.getElementById("teammembers"), {
        onEnd: function() {
            $(".teammember-can-order-form").each(function(i){
                $(this).find('input[id$=ORDER]').val(i+1);
            });
        }
    });
    // Extra Fields
    bind_add_button($('#add-extrafield').on('click', bind_add_button('extrafield')));

    function bind_add_button(form) {
        return function (event) {
            event.preventDefault();
            var total = $('#id_agent' + form + '_set-TOTAL_FORMS');
            var initial = $('#id_agent' + form + '_set-INITIAL_FORMS');
            var templateRowStr = $('#' + form + '_empty_form').html();
            var newTotal = parseInt(total.val());
            var appendRowStr = templateRowStr.replace(/__prefix__/g, newTotal);
            total.val(newTotal + 1);
            $('#' + form + 's').append(appendRowStr);
            initSelectize();
        }
    }

}

/*
 * Stocklists
 */
$('#id_propertytype_option_0').prop('checked', 'checked');
$('#id_propertytype_option_0').parent().parent().find('.form-check input').not('#id_propertytype_option_0').prop('disabled', 'disabled');
$('#id_propertytype_option_0').on('change', function () {
    if ($(this).is(':checked')){
        $(this).parent().parent().find('.form-check input').not(this).prop('disabled', 'disabled').prop('checked', false);
    } else {
      $(this).parent().parent().find('.form-check input').prop('disabled', false);
    }
  });

$('#id_contract_option_0').prop('checked', 'checked');
$('#id_contract_option_0').parent().parent().find('.form-check input').not('#id_contract_option_0').prop('disabled', 'disabled');
$('#id_contract_option_0').on('change', function () {
    if ($(this).is(':checked')){
        $(this).parent().parent().find('.form-check input').not(this).prop('disabled', 'disabled').prop('checked', false);
    } else {
        $(this).parent().parent().find('.form-check input').prop('disabled', false);
    }
});

/*
 * Listing Brochure
 */
if(body_el.classList.contains("brochure")) {
    $("#report_form").submit(function (e) {
        // preventing from page reload and default actions
        e.preventDefault();
        var element = document.getElementById("id_listing");
        var listing_id = element.options[element.selectedIndex].value;
        var url="/_print/listing/"+listing_id+"/"
        window.open(url,"_blank");
    })
}

/*
 * Featured Listings
 */
if(body_el.classList.contains("featured")) {
    $("#id_add-fav").on("change", function() {
        if($("#id_add-fav").val() != ""){
            if($("#listing-order").val() == "") {
                $("#listing-order").val($(this).val());
            } else {
                $("#listing-order").val($(this).val() + "," + $("#listing-order").val());
            }
                $("#feature-order").trigger("submit");
        }
    });

    Sortable.create(document.getElementById("sortable"), {
        store: {
            set: function (sortable) {
                var new_order = sortable.toArray().toString();
                console.log(new_order);
                $("#listing-order").val(new_order);
            }
        }
    });

    $(".delete-listing").on("click", function() {
        $("#listing-order").val( $("#listing-order").val().replace($(this).data('id')+",", ""));
        $("#listing-order").val( $("#listing-order").val().replace(","+$(this).data('id'), ""));
        $("#listing-order").val( $("#listing-order").val().replace($(this).data('id'), ""));
        $("#feature-order").trigger("submit");
    });
}

/*
 * Slideshow
 */
if(body_el.classList.contains("slideshow")) {
    coloris({alpha: false, theme: "polaroid", el: "#color1"});
    coloris({alpha: false, theme: "polaroid", el: "#color2"});
}

/*
 * QR Code
 */
if(body_el.classList.contains("qr-code")) {
    $('#id_generate_custom_url').on('change', function () {
        if (this.checked)
        {
           $('#custom_url').show();
           $('#unique_id').hide();
           $('#id_unique_id').val('');
        }
        else {
            $('#custom_url').hide();
            $('#unique_id').show();
            $('#id_custom_url').val('');
        }
    });

    if ($("#id_generate_custom_url").is(':checked')){
        $('#custom_url').show();
        $('#unique_id').hide();
        $('#id_unique_id').val('');
    }else{
        $('#custom_url').hide();
        $('#unique_id').show();
        $('#id_custom_url').val('');
    }
}

/* 
 * Listings
 */

/* 
 * Building Name
 */
const initBuildingNames = () => {
    $.ajax({
        url: '/get-building-names/',
        cache: false,
        dataType: 'json',
        success: function (data) {
            let label = $("input#id_listing-building_name").prev();
            let currentValue = $("input#id_listing-building_name").val();
            let optionsString = '<select class="form-select search-select" name="listing-building_name" id="id_listing-building_name">';
            optionsString = optionsString + '<option value="">Select a Building</option>'
            $.each(data, function (id, ele) {
                let thisOption = '';
                ele = ele.replace(/&#x27;/g, "'");
                if (ele != "") {
                    thisOption = '<option value="' + ele + '" ';
                    if (ele == currentValue) {
                        thisOption = thisOption + 'selected="selected">' + ele + '</option>';
                    } else {
                        thisOption = thisOption + '">' + ele + '</option>';
                    }
                }
                optionsString = optionsString + thisOption;
            });
            optionsString = optionsString + '</select>';
            $("input#id_listing-building_name").remove();
            $(label).parent().append(optionsString);
            initSelectize();
        }
    });
};

if (!$('#id_listing-is_building').prop("checked")) {
    initBuildingNames();
}

$('#id_listing-is_building').on("change", function () {
    if ($('#id_listing-is_building').prop("checked")) {
        let label = $("select#id_listing-building_name").prev();
        $("select#id_listing-building_name")[0].selectize.destroy()
        $("select#id_listing-building_name").remove();
        $(label).parent().append('<input id="id_listing-building_name" class="form-control" type="text" name="listing-building_name" maxlength="50">');
    } else {
        initBuildingNames();
    }
});

/*
 * Show/hide sale/lease forms
 */
let current_listing_type = '';
const sale_form = document.getElementById("display-sale-form");
const rent_form = document.getElementById("display-rent-form");

const updateListingTypeForms = (field) => {
    if(field.checked) {
        current_listing_type = field.value;
        if(current_listing_type == "Both") {
            sale_form.classList.remove("d-none");
            if(rent_form) {
                rent_form.classList.remove("d-none");
            }
        } else if(current_listing_type == "Lease") {
            sale_form.classList.add("d-none");
            if(rent_form) {
                rent_form.classList.remove("d-none");
            }
        } else {
            sale_form.classList.remove("d-none");
            if(rent_form) {
                rent_form.classList.add("d-none");
            }
        }
    }
}

document.querySelectorAll('input[name="listing-listing_type"]').forEach(field => {
    updateListingTypeForms(field);
    
    field.addEventListener("change", () => {
        updateListingTypeForms(field);
    });
});

/*
 * Category Show/Hide
 */
let current_property_type = '';
let listing_rea_property_type = document.getElementById("id_listing-rea_property_type");
let rea_property_type = document.getElementById("id_rea_property_type");

const residential_category = document.getElementById("display-residential-category");
const rental_category = document.getElementById("display-rental-category");
const rural_category = document.getElementById("display-rural-category");
const holiday_category = document.getElementById("display-holiday-category");
const rural_features = document.getElementById("display-rural-features");

const updateListingForm = (current_property_type) => {
        if(residential_category) {
            residential_category.classList.add("d-none");
        }
        if(rental_category) {
            rental_category.classList.add("d-none");
        }
        if(rural_category) {
            rural_category.classList.add("d-none");
        }
        if(holiday_category) {
            holiday_category.classList.add("d-none");
        }
        if(rural_features) {
            rural_features.classList.add("d-none");
        }
        if(current_property_type == 'Residential') {
            if(residential_category) {
                residential_category.classList.remove("d-none");
            }
            $('#display-rural-category select').attr('selectedIndex', '0');
            $('#display-holiday-category select').attr('selectedIndex', '0');
        }
        if(current_property_type == 'Rental') {
            if(rental_category) {
                rental_category.classList.remove("d-none");
            }
            $('#display-rural-category select').attr('selectedIndex', '0');
            $('#display-holiday-category select').attr('selectedIndex', '0');
        } 
        if(current_property_type == 'Rural') {
            if(rural_category) {
                rural_category.classList.remove("d-none");
            }
            if(rural_features) {
                rural_features.classList.remove("d-none");
            }
            $('#display-residential-category select').attr('selectedIndex', '0');
            $('#display-holiday-category select').attr('selectedIndex', '0');
        } 
        if(current_property_type == 'HolidayRental') {
            if(holiday_category) {
                holiday_category.classList.remove("d-none");
            }
            $('#display-residential-category select').attr('selectedIndex', '0');
            $('#display-rural-category select').attr('selectedIndex', '0');
            $('#id_listing-listing_type_1').parents('li').show();
            $('#id_listing-listing_type_1').parents('label').click();
            $('#id_listing-listing_type_0').parents('li').hide();
        }
        if(current_property_type == '' || current_property_type == 'Land') {
            $('#display-residential-category select').attr('selectedIndex', '0');
            $('#display-rural-category select').attr('selectedIndex', '0');
            $('#display-holiday-category select').attr('selectedIndex', '0');
            $('#id_listing-listing_type_0').parents('li').show();
            $('#id_listing-listing_type_0').parents('label').click();
            $('#id_listing-listing_type_1').parents('li').hide();
        }
};

if(listing_rea_property_type) {
    current_property_type = listing_rea_property_type.value;
    updateListingForm(current_property_type);
    listing_rea_property_type.addEventListener("change", () => {
        current_property_type = listing_rea_property_type.value;
        updateListingForm(current_property_type);
    });
}
if(rea_property_type) {
    current_property_type = rea_property_type.value;
    updateListingForm(current_property_type);
    rea_property_type.addEventListener("change", () => {
        current_property_type = rea_property_type.value;
        updateListingForm(current_property_type);
    });
}

/* 
 * Show/hide extra status
 */
const showHideExtraStatus = () => {
    let display_extra_status = document.getElementById("display-extra-status");

    document.querySelectorAll('input[name="listing-property_status"]').forEach(field => {
        field.addEventListener("change", () => {
            if(field.value == "Available") {
                display_extra_status.classList.remove("d-none");
                clearSoldDetails();
                clearLeasedDetails();
            } else {
                display_extra_status.classList.add("d-none");
            }
            if(field.value == "Sold") {
                clearLeasedDetails();
            }
            if(field.value == "Leased") {
                clearSoldDetails();
            }
            if(field.value == "Withdrawn" || field.value == "Offmarket" || field.value == "Deleted") {
                clearSoldDetails();
                clearLeasedDetails();
            }
            showHideSoldLeasedArchiveWithdrawnDetails();
        });
    });
};

/* 
 * Clear sold fields when property status is not "Sold"
 */
const clearSoldDetails = () => {
    document.getElementById("id_sale-sold_details_sold_method").selectedIndex = 0;
    document.getElementById("id_sale-sold_details_sold_date").value = "";
    document.getElementById("id_sale-sold_details_sold_price").value = "";
    document.getElementById("id_sale-sold_details_sold_price_display").checked = true;
};

/* 
 * Clear leased fields when proeprty status is not "Leased"
 */
const clearLeasedDetails = () => {
    try {
        document.getElementById("id_rent-rented_details_rent_date").value = "";
        document.getElementById("id_rent-rented_details_rent_duration").value = "";
        document.getElementById("id_rent-rented_details_rent_price").value = "";
    } catch(error) {}
};

/* 
 * Show/hide archive & withdrawn fields
 */
const showHideSoldLeasedArchiveWithdrawnDetails = () => {
    let property_status = "Available";
    if(document.querySelector('input[name="listing-property_status"]:checked')) {
        property_status = document.querySelector('input[name="listing-property_status"]:checked').value;
    }
    let display_sold_details = document.getElementById("display-sold-details");
    let display_leased_details = document.getElementById("display-leased-details");

    if(display_sold_details) {
        if(property_status != "Sold") {
            display_sold_details.classList.add("d-none");
        } else {
            display_sold_details.classList.remove("d-none");
        }
    }
    if(display_leased_details) {
        if(property_status != "Leased") {
            display_leased_details.classList.add("d-none");
        } else {
            display_leased_details.classList.remove("d-none");
        }
    }
    if(property_status != "Sold" && property_status != "Leased") {
        document.getElementById("display-archive-details").classList.add("d-none");
    } else {
        document.getElementById("display-archive-details").classList.remove("d-none");
    }
    if(property_status != "Withdrawn") {
        document.getElementById("display-withdrawn-details").classList.add("d-none");
    } else {
        document.getElementById("display-withdrawn-details").classList.remove("d-none");
    }
};

/*
 * Show/hide auction details
 */
const showHideAuctionDetails = () => {
    let sale_type = document.getElementById("id_listing-sale_type");
    if(sale_type) {
        sale_type.addEventListener("change", () => {
            if(sale_type.value == "Auction") {
                document.getElementById("id_listing-auction_date").value = "";
                document.getElementById("id_listing-auction_time").value = "";
                document.getElementById("id_listing-auction_venue").value = "";
                document.getElementById("display-auction-form").classList.remove("d-none");
            } else {
                document.getElementById("display-auction-form").classList.add("d-none");
            }
        });
    }
};

const manageIntFields = () => {
    let year_built = document.getElementById("id_listing-year_built");
    let bedrooms = document.getElementById("id_listing-bedrooms");
    let rooms = document.getElementById("id_listing-rooms");
    let living_areas = document.getElementById("id_listing-living_areas");
    let bathrooms = document.getElementById("id_listing-bathrooms");
    let ensuites = document.getElementById("id_listing-ensuites");
    let toilets = document.getElementById("id_listing-toilets");
    let parking_garages = document.getElementById("id_listing-parking_garages");
    let parking_carports = document.getElementById("id_listing-parking_carports");
    let parking_other = document.getElementById("id_listing-parking_other");
    let parking_total = document.getElementById("id_listing-parking_total");

    [year_built, bedrooms, rooms, living_areas, bathrooms, ensuites, toilets, parking_garages, parking_carports, parking_other, parking_total].forEach((element) => {
        try {
            element.addEventListener("keyup", () => {
                if(element.value.indexOf("0") == 0) {
                    element.value = element.value.replace(/^0+/,"");
                }
            });
        } catch (error) {
            //console.log(error);
        }
    });

    [parking_garages, parking_carports, parking_other].forEach((element) => {
        try {
            element.addEventListener("keyup", () => {
                let total = 0;
                [parking_garages, parking_carports, parking_other].forEach((element) => {
                    if(element.value != "") {
                        total += parseInt(element.value);
                    }
                });
                parking_total.value = total;
            });
        } catch (error) {
            //console.log(error);
        }
    });
};

function enable_disable_multiload(url, option) {
    $.ajax({
        url:'/'+option+'-multiload'+url + '/',
        cache: false,
        success: function(){}
    });
}

if($("#listing-portals").length > 0) {
    $.ajax({
        url: $("#listing-portals").data("endpoint"),
        cache: false,
        success: function (data) {
            $("#listing-portals").html(data);
            document.querySelectorAll('.portal-checkbox').forEach(field => {
                field.addEventListener("change", () => {
                    let option = 'disable';
                    if(field.checked) {
                        option = 'enable';
                    }
                    enable_disable_multiload(field.dataset.endpoint, option);
                });
            });
        }
    });
}

// Common
if(body_el.classList.contains("details")) {
    updateListingForm(current_property_type);
    showHideExtraStatus();
    showHideSoldLeasedArchiveWithdrawnDetails();
    showHideAuctionDetails();
    manageIntFields();
    bind_add_button($('#add-inspection-time').on('click', bind_add_button('inspection_time')));
    bind_add_button($('#add-building-area').on('click', bind_add_button('building')));
    bind_add_button($('#add-extra_fields').on('click', bind_add_button('extra_fields')));

    function bind_add_button(form) {
        return function (event) {
            event.preventDefault();
            var total = $('#id_' + form + '-TOTAL_FORMS');
            var initial = $('#id_' + form + '-INITIAL_FORMS');
            var templateRowStr = $('#' + form + '_empty_form').html();
            var newTotal = parseInt(total.val());
            var appendRowStr = templateRowStr.replace(/__prefix__/g, newTotal);
            total.val(newTotal + 1);
            $('#' + form + 's').append(appendRowStr);
            initPickers();
            initSelectize();
        }
    }
}

// Media
if(body_el.classList.contains("media")) {
    // Configure Dropzone Options
    var imageOptions = {
        dictDefaultMessage: "Drop JPG or GIF files here to upload",
        acceptedFiles:".gif,.jpg,.jpeg",
        parallelUploads: 1,
        maxFiles:img_limit,
        maxFilesize: 10485760, //10MB
        url:`/upload_media/img/${listing_id}/`,
    };
    var floorplanOptions = {
        acceptedFiles: ".gif,.jpg,.jpeg",
        parallelUploads: 1,
        maxFiles: floorplan_limit,
        maxFilesize: 10485760, //10MB
        url: `/upload_media/floorplan/${listing_id}/`,
    };
    var documentOptions = {
        acceptedFiles: ".pdf",
        parallelUploads: 1,
        maxFiles: document_limit,
        maxFilesize: 10485760, //10MB
        url: `/upload_media/document/${listing_id}/`,
    };
    var attachmentOptions = {
        paramName: "async-upload-3",
        parallelUploads: 1,
        maxFiles: attachment_limit,
        maxFilesize: 10485760, //10MB
        url: `/upload_media/attachment/${listing_id}/`,
    };

    let imageDropzone = new Dropzone("#img-dropzone", imageOptions);
    imageDropzone.on("complete", function(file) {
        imageDropzone.removeFile(file);
    });
    imageDropzone.on("queuecomplete", function(file) {
        getObjects('img');
    });

    let floorplanDropzone = new Dropzone("#floorplan-dropzone", floorplanOptions);
    floorplanDropzone.on("complete", function(file) {
        floorplanDropzone.removeFile(file);
    });
    floorplanDropzone.on("queuecomplete", function(file) {
        getObjects('floorplan');
    });

    let documentDropzone = new Dropzone("#document-dropzone", documentOptions);
    documentDropzone.on("complete", function(file) {
        documentDropzone.removeFile(file);
    });
    documentDropzone.on("queuecomplete", function(file) {
        getObjects('document');
    });

    let attachmentDropzone = new Dropzone("#attachment-dropzone", attachmentOptions);
    attachmentDropzone.on("complete", function(file) {
        attachmentDropzone.removeFile(file);
    });
    attachmentDropzone.on("queuecomplete", function(file) {
        getObjects('attachment');
    });

    // Sortable
    var sortables = [];
    var imageSort = document.getElementById('img-sortable');
    sortables["img"] = Sortable.create(imageSort, {
        onEnd: function() {
            updateObjects("img");
        }
    });

    var floorplanSort = document.getElementById('floorplan-sortable');
    sortables["floorplan"] = Sortable.create(floorplanSort, {
        onEnd: function() {
            updateObjects("floorplan");
        }
    });

    var documentSort = document.getElementById('document-sortable');
    sortables["document"] = Sortable.create(documentSort, {
        onEnd: function() {
            updateObjects("document");
        }
    });

    getObjects('img');
    getObjects('floorplan');
    getObjects('document');
    getObjects('attachment');

    function initMediaDelete(object_type) {
        let options = {
            confirm: "Are you sure you want to delete this?"
        };
        $('#'+object_type+'-sortable .object-delete').each(function() {
            $(this).confirmButton(options);
            $(this).on('click', function(e) {
                e.preventDefault();
                removeObject($(this).data("objectType"), $(this).data("objectId"));
                return false;
            });
        }); 
    }

    function getObjects(object_type) {
        var imgTemplate = $('#img-template')[0]
        var floorplanTemplate = $('#floorplan-template')[0]
        var documentTemplate = $('#document-template')[0]
        var attachmentTemplate = $('#attachment-template')[0]
        $.ajax({
            url: `/retrieve_media/${object_type}/${listing_id}/medium/`,
            cache: false,
            dataType: 'json',
            success: function(data) {
                var count = 0;

                $('#'+object_type+'-sortable').empty();
                $('#'+object_type+'-list').empty();

                // Iterate through retrieved data.
                //	Documents will be populated as a list of file names.
                //	Images and floorplans will be sortable lists.

                // Hard coded, determines when enough is enough.
                //	If the object limit is hit, the 'upload' button is removed.
                $.each(data, function(id, obj) {
                    var random = Math.floor(Math.random() * 10000);
                    count++;
                    if( object_type == 'img') {
                        var html = imgTemplate.text.replace(/__file__/g, obj.file).replace(/__id__/g, obj.id);
                        $(html).appendTo('#img-sortable');
                        initTooltips();
                    } else if( object_type == 'floorplan') {
                        var html = floorplanTemplate.text.replace(/__file__/g, obj.file).replace(/__id__/g, obj.id);
                        $(html).appendTo('#floorplan-sortable');
                        initTooltips();
                    } else if( object_type == 'document' ) {
                        var title = obj.filename;
                        if(obj.title) {
                            title = obj.title;
                        }
                        var html = documentTemplate.text.replace(/__file__/g, obj.file).replace(/__title__/g, title).replace(/__id__/g, obj.id);
                        $(html).appendTo('#document-sortable');
                        $('#document_title_'+obj.id).blur(function() {
                            updateTitles();
                        });
                    } else if (object_type == 'attachment') {
                        var prefix = '0';
                        var filename = obj.filename.split('/');
                        filename = filename[filename.length - 1];
                        var html = attachmentTemplate.text.replace(/__prefix__/g, '' + prefix + '').replace(/__filename__/g, filename).replace(/__file__/g, obj.file).replace(/__id__/g, obj.id);
                        $(html).appendTo('#attachment-sortable');
                        $('#id_media_attachment-' + prefix + '-id').val(obj.id);
                        $('#id_media_attachment-' + prefix + '-usage').val("statementOfInformation");
                        $('.delete-attachment').click(function(e) { e.preventDefault(); removeObject('attachment', this.dataset.id); return false; });
                    }
                });
            
                if( object_type == 'img' ) {
                    if( count >= img_max ) {
                        $('#img-dropzone').addClass("d-none");
                        $('#img-warning').removeClass("d-none");
                    } else {
                        $('#img-dropzone').removeClass("d-none");
                        $('#img-warning').addClass("d-none");
                    }
                } else if( object_type == 'floorplan' ) {
                    if( count >= floorplan_max ) {
                        $('#floorplan-dropzone').addClass("d-none");
                        $('#floorplan-warning').removeClass("d-none");
                    } else {
                        $('#floorplan-dropzone').removeClass("d-none");
                        $('#floorplan-warning').addClass("d-none");
                    }
                } else if( object_type == 'document' ) {
                    if( count >= document_max ) {
                        $('#document-dropzone').addClass("d-none");
                        $('#document-warning').removeClass("d-none");
                    } else {
                        $('#document-dropzone').removeClass("d-none");
                        $('#document-warning').addClass("d-none");
                    }
                } else if( object_type == 'attachment' ) {
                    if( count >= attachment_max ) {
                        $('#attachment-dropzone').addClass("d-none");
                        $('#attachment-warning').removeClass("d-none");
                    } else {
                        $('#attachment-dropzone').removeClass("d-none");
                        $('#attachment-warning').addClass("d-none");
                    }
                    $('#id_media_attachment-INITIAL_FORMS').val(count);
                    $('#id_media_attachment-TOTAL_FORMS').val(count);
                }

                initMediaDelete(object_type);
            }

        });
    }


    /* 
    * Update objects based on type
    */
    function updateObjects(object_type) {
        $.ajax({
            url: `/update_media/${object_type}/${listing_id}/`,
            cache: false,
            type: 'POST',
            data: ({ fileOrder: `\'${sortables[object_type].toArray()}\'` }),
            success: function() {
                getObjects(object_type);
            },
            complete: function() {
                
            }
        });
    }

    /* 
    * Delete object
    */
    function removeObject(object_type, id) {
        $.ajax({
            url: `/delete_media/${object_type}/${listing_id}/`,
            cache: false,
            type: 'POST',
            data: "delete_id="+id,
            success: function() {
                if( object_type == 'img' ) {
                    $('#'+object_type+'_'+id).remove();
                    setImageLimit(img_limit + 1);
                    setTimeout(updateObjects, 200, "img");
                } else if( object_type == 'floorplan' ) {
                    $('#'+object_type+'_'+id).remove();
                    setFloorplanLimit(floorplan_limit + 1);
                    setTimeout(updateObjects, 200, "floorplan");
                } else if( object_type == 'document' ) {
                    $('#'+object_type+'_'+id).remove();
                    setDocumentLimit(document_limit + 1);
                } else if( object_type == 'attachment' ) {
                    $('#'+object_type+'_'+id).remove();
                    setAttachmentLimit(attachment_limit + 1);
                    var count = $('.media-attachment-form').length;
                    $('#id_media_attachment-INITIAL_FORMS').val(count);
                    $('#id_media_attachment-TOTAL_FORMS').val(count);
                    setTimeout(getObjects, 200, "attachment");
                }
            }
        });
    }

    /*
    * Update Document Titles
    */
    function updateTitles() {
        $.ajax({
            url: `/update_media/update-titles/${listing_id}/`,
            cache: false,
            type: 'POST',
            data: ({ 
                    document_title_01: $('#document_title_1').val(),
                    document_title_02: $('#document_title_2').val(),	
                    document_title_03: $('#document_title_3').val(),
                    document_title_04: $('#document_title_4').val(),
                    document_title_05: $('#document_title_5').val(),
                    document_title_06: $('#document_title_6').val(),
                    document_title_07: $('#document_title_7').val(),
                    document_title_08: $('#document_title_8').val(),
                    document_title_09: $('#document_title_9').val(),
                    document_title_10: $('#document_title_10').val()	
                }),
            success: function() {
            
            }
        });
    }

    function setImageLimit(num) {
        img_limit = num;
        imageDropzone.options.maxFiles = img_limit;
    }

    function setFloorplanLimit(num) {
        floorplan_limit = num;
        floorplanDropzone.options.maxFiles = floorplan_limit;
    }

    function setDocumentLimit(num) {
        document_limit = num;
        documentDropzone.options.maxFiles = document_limit;
    }

    function setAttachmentLimit(num) {
        attachment_limit = num;
        attachmentDropzone.options.maxFiles = attachment_limit;
    }

    /*
    * Add extra external link forms
    */
    var externalTemplate = $('#external-template')[0];
    $('#add-external-link').on("click", function(e){
        e.preventDefault();
        var numRows = parseInt($('#id_form-TOTAL_FORMS').val()) + 1;
        $("#id_form-TOTAL_FORMS").val(numRows);
        var newFormId = numRows - 1;
        var html = externalTemplate.text.replace(/__prefix__/g, newFormId);
        $(html).appendTo('#external-links');
        return false;
    });

}

// REA
if(body_el.classList.contains("rea")) {
  
}

// DOMAIN
if(body_el.classList.contains("domain")) {
    
}

if(body_el.classList.contains("agent-form")) {
    // Configure Dropzone Options
    var personalImageOptions = {
        dictDefaultMessage: "Drop JPG or GIF files here to upload",
        parallelUploads: 1,
        maxFilesize: 10485760, //10MB
        acceptedFiles:".gif,.jpg,.jpeg",
        maxFiles:personal_limit,
        url:`/dashboard/settings/agents/agent-upload-photo/${agent_id}/personal/`,
    };
    var iawImageOptions = {
        dictDefaultMessage: "Drop JPG or GIF files here to upload",
        parallelUploads: 1,
        maxFilesize: 10485760, //10MB
        acceptedFiles:".gif,.jpg,.jpeg",
        maxFiles:iaw_limit,
        url:`/dashboard/settings/agents/upload-images/${agent_id}/`,
    };

    let personalImageDropzone = new Dropzone("#personal-img-dropzone", personalImageOptions);
    personalImageDropzone.on("complete", function(file) {
        personalImageDropzone.removeFile(file);
    });
    personalImageDropzone.on("queuecomplete", function(file) {
        getObjects('personal');
    });

    let iawImageDropzone = new Dropzone("#iaw-img-dropzone", iawImageOptions);
    iawImageDropzone.on("complete", function(file) {
        iawImageDropzone.removeFile(file);
    });
    iawImageDropzone.on("queuecomplete", function(file) {
        getObjects('iaw');
    });

    function setPersonalLimit(num) {
        personal_limit = num;
        personalImageDropzone.options.maxFiles = personal_limit;
    }

    function setIawLimit(num) {
        iaw_limit = num;
        iawImageDropzone.options.maxFiles = iaw_limit;
    }

    // Sortable
    var sortables = [];
    var imageSort = document.getElementById('personal-sortable');
    sortables["personal"] = Sortable.create(imageSort, {
        onEnd: function() {
            updateObjects("personal");
        }
    });

    var floorplanSort = document.getElementById('iaw-sortable');
    sortables["iaw"] = Sortable.create(floorplanSort, {
        onEnd: function() {
            updateObjects("iaw");
        }
    });

    getObjects('personal');
    getObjects('iaw');

    function initMediaDelete(object_type) {
        let options = {
            confirm: "Are you sure you want to delete this?"
        };
        $('#'+object_type+'-sortable .object-delete').each(function() {
            $(this).confirmButton(options);
            $(this).on('click', function(e) {
                e.preventDefault();
                removeObject($(this).data("objectType"), $(this).data("objectId"));
                return false;
            });
        }); 
    }

    function getObjects(object_type) {
        var imgTemplate = $('#personal-template')[0]
        var iawTemplate = $('#iaw-template')[0]
        if( object_type == 'personal') {
            var $url = `/dashboard/settings/agents/agent-retrieve-photo/${agent_id}/${object_type}/`;
            var datatype = 'text';
        } else if( object_type == 'iaw') {
            var $url = `/dashboard/settings/agents/retrieve-images/${agent_id}/`;
            var datatype = 'json';
        }

        $.ajax({
            url: $url,
            cache: false,
            dataType: datatype,
            success: function(data) {
                var count = 0;
                $('#'+object_type+'-sortable').empty();
                $('#'+object_type+'-list').empty();
                if( object_type == 'personal' && data.includes("jpg")) {
                    count++;
                    var html = imgTemplate.text.replace(/__file__/g, data).replace(/__id__/g, count);
                    $(html).appendTo('#personal-sortable');
                    initTooltips();
                    if( count >= personal_limit ) {
                        $('#personal-img-dropzone').addClass("d-none");
                        $('#personal-img-warning').removeClass("d-none");
                    } else {
                        $('#personal-img-dropzone').removeClass("d-none");
                        $('#personal-img-warning').addClass("d-none");
                    }
                }

                if( object_type == 'iaw') {
                    $.each(data, function(id, obj) {
                        var random = Math.floor(Math.random() * 10000);
                        count++;
                        
                            var html = iawTemplate.text.replace(/__file__/g, obj.file).replace(/__id__/g, obj.id);
                            $(html).appendTo('#iaw-sortable');
                            initTooltips();
                        
                    });
            
                    if( count >= iaw_limit ) {
                        $('#iaw-dropzone').addClass("d-none");
                        $('#iaw-warning').removeClass("d-none");
                    } else {
                        $('#iaw-dropzone').removeClass("d-none");
                        $('#iaw-warning').addClass("d-none");
                    }
                }

                initMediaDelete(object_type);
            }

        });
    }


    /* 
    * Update objects based on type
    */
    function updateObjects(object_type) {
        $.ajax({
            url: `/dashboard/settings/agents/update-images/${agent_id}/`,
            cache: false,
            type: 'POST',
            data: ({ fileOrder: `\'${sortables[object_type].toArray()}\'` }),
            success: function() {
                getObjects(object_type);
            },
            complete: function() {
                
            }
        });
    }

    /* 
    * Delete object
    */
    function removeObject(object_type, id) {
        if( object_type == 'personal' ) {
            var url = `/dashboard/settings/agents/agent-delete-photo/${agent_id}/${object_type}/`;
            $.ajax({
                url: url,
                cache: false,
                success: function() {
                    $('#'+object_type+'_'+id).remove();
                    setPersonalLimit(1);
                    $('#personal-img-dropzone').removeClass("d-none");
                    $('#personal-img-warning').addClass("d-none");
                }
            });
        } else if( object_type == 'iaw' ) {
            var url = `/dashboard/settings/agents/delete-images/${agent_id}/`;
            $.ajax({
                url: url,
                cache: false,
                type: 'POST',
                data: "delete_id="+id,
                success: function() {
                    $('#'+object_type+'_'+id).remove();
                    setIawLimit(iaw_limit + 1);
                    setTimeout(updateObjects, 200, "iaw");
                }
            });
        }
    }
}